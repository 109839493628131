import React from "react";
import Conclusion from "../../components/project/Conclusion";
import CommonProjectComponent from "../../components/project/CommonProjectComponent";
import ProblemSolution from "../../components/project/ProblemSolution";
import ProjectDetailBanner from "../../components/project/ProjectDetailBanner";
import PreviousNext from "../../components/PreviousNext";
import { Seo } from "../../components/seo";
import Layout from "../../components/layout";

const SurveyHelpManual = () => {
  const headingbanner = {
    title: `S2C Help Manual`,
    content: `A Research Tech Platform`,
  };

  const data = {
    images: ["help-manual.webp", "help-manual-2.webp"],
    firstAlt: "UI UX Screen To Create Survey",
    firstTitle: "Create Survey",
    secondAlt: "UI Screen To Create Personalized Survey Using Specified Categories",
    secondTitle: "Select Category",
  };

  const problemSolutionContent = {
    sidebar: [
      {
        title: "Industry",
        text: ["Enterprise"],
      },
      {
        title: "What we did",
        text: ["UX Consultation", "UI UX Designing", "Wordpress Development"],
      },
      {
        title: "Platform",
        text: ["Web Application"],
      },
    ],
    mainContent: [
      {
        title: "Problem",
        content: [
          `The current help manual of the platform lacked clarity and effectiveness resulting in users facing difficulties in understanding the product's features.`,
          `The lack of comprehensive instructions and visual aid in the manual contributes to user frustration, increased support request and decreased product adoption. `,
          `There was a need to improve the user manual to provide clear, concise and user-friendly guidance that empowers them to troubleshoot issues, understand product functionalities and optimize the overall user experience.`,
        ],
      },
      {
        title: "Solution",
        content: [
          `In order to address the challenges, we aim to create a comprehensive and user-friendly solution that effectively assists users in understanding product's features.`,
          `We revamped the manual by incorporating clear and concise instructions, intuitive designs and offered visual aid with diagrams, screenshots, step-by-step tutorials etc. `,
          `The revised help manual provided users with a seamless experience, enabling them to troubleshoot issues independently, discover hidden features and maximise their overall satisfaction and productivity with the product.`,
          `By delivering an improved help manual, we reduced support requests, enhanced user adoption and fostered a positive user experience.`,
        ],
      },
    ],
    image: ["help-manual-problem.webp"],
  };

  const projectData = {
    title: "Help Center",
    para: [
      `Your go-to resource for comprehensive guidance, troubleshooting assistance, and valuable information to optimise your experience with our product.`,
    ],
    content: {
      image: ["help-manual-projectone.webp"],
    },
  };

  const projectData2 = {
    title: `How to Create Survey`,
    para: [
      `Learn how to create surveys effectively with our comprehensive guide, covering everything from survey design and question types to data analysis and actionable insights, empowering you to gather valuable feedback and make informed decisions.`,
    ],
    content: {
      image: ["help-manual-projecttwo.webp"],
    },
  };

  const projectData3 = {
    title: `How to Add Questions In The Survey`,
    para: [
      `Discover the step-by-step process of adding questions to your survey effortlessly, including selecting question types, customising options, and arranging the sequence, enabling you to design a comprehensive and engaging survey tailored to your specific research objectives.`,
    ],
    content: {
      image: [
        "help-manual-projectthree.webp",
        "help-manual-projectthree-2.webp",
        "help-manual-projectthree-3.webp",
      ],
    },
  };

  const conclusionData = [
    {
      para: [
        `In conclusion, our improved help manual served as a valuable resource to empower users, providing clear instructions, intuitive designs, and visual aids that enhanced user understanding and utilization of the product. `,
        `By addressing the limitations of the previous manual, we reduced user frustration, support requests, and promote a seamless user experience.`,
      ],
    },
  ];

  return (
    <Layout>
      <div className="service-page">
        <ProjectDetailBanner data={data} headingbanner={headingbanner} />
        <ProblemSolution data={problemSolutionContent} />
        <CommonProjectComponent data={projectData} mode="cream" />
        <CommonProjectComponent data={projectData2} />
        <CommonProjectComponent data={projectData3} mode="dark" />
        <Conclusion conclusionData={conclusionData} />
        <PreviousNext currentProjectId={23} />
      </div>
    </Layout>
  );
};

export default SurveyHelpManual;

export const Head = () => (
  <Seo title="Survey2connect Help Manual - Octet Design Studio" description="With our services on UX Consultation, UI UX Designing and WordPress Development, we successfully simplified the knowledge base for an enterprise CX platform." />
)